import React from 'react';

import './styles.scss';
import Layout from '../../components/layout/Layout';
import LoginForm from '../../components/LoginForm';
import ResetPasswordForm from '../../components/ResetPasswordForm';

const Logo = require('../../assets/images/MeridianLogo.jpg');

export default function () {

    const { match } = this.props;

    const { token } = match.params;

    return (
        <Layout
            className="login"
            title={<div>Login</div>}
            showHeader={false}
            showFooter={false}>
            <div className="background-wrapper">
                <div className="logo">
                    <img src={Logo} alt="" />
                    Client Portal
                </div>
                <div className="description">
                    Welcome to the Meridian Integrated Systems Ltd portal. If you require any assistance logging in to the portal, please contact us on 0121 386 6680
                </div>
            </div>

            <LoginForm />
            <ResetPasswordForm token={token} isVisible={this.state.changePasswordModal} onCancel={() => this.setState({ changePasswordModal: false })} onOk={() => this.setState({ changePasswordModal: false })} onSubmitSuccess={() => this.setState({ changePasswordModal: false })} />
        </Layout>
    );
}
