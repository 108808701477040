import React from 'react';
import {Col, Row} from 'antd';
import File from '../../components/File';

import Layout from '../../components/layout/Layout';

import './styles.scss';

export default function () {
    const { userSettings, image } = this.props;

    return (
        <Layout className="information" footerContent={null}>

            <div className="pre-company">
                <div className="description">
                    <div className="title">
                        {userSettings.company.title}
                    </div>
                    {userSettings.company.description}
                </div>
                <img className="image-company" src={image} alt="" />
            </div>

            <div className="lastUsedFile">

                <div className="title">Your recently accessed files</div>
                {
                    userSettings.recentlyTwoFiles !== null
                        ? (
                            <div>
                                <Row gutter={[48, 24]} className="row-files">
                                    {
                                        userSettings.recentlyTwoFiles.map(value => (
                                            <Col span={12}>
                                                <File
                                                    fileName={value.name}
                                                    file_id={value.id}
                                                    fileExt={value.ext}
                                                    tag={value.tags}
                                                    ownerFile={value.updated_by ? `updated by ${value.updated_by}` : ''}
                                                    fileSize={value.size ? value.size : '0.0 Mb'}
                                                    uploadedAt={`${value.download_at}`} />
                                            </Col>
                                        ))
                                    }
                                </Row>

                                <div className="allFiles">
                                    <button className="btnFiles" type="button" onClick={this.handleAllFiles}>
                                        All files
                                    </button>
                                </div>
                            </div>
                        )
                        : (
                            <div className="allFiles">
                                No uploaded files
                            </div>
                        )
                }

            </div>

        </Layout>
    );
}
