import axios from 'axios';
import lockr from 'lockr';
import { isUndefined, isEmpty, isObject } from 'underscore';

const {
    API_URL_DEV,
    API_URL,
    AUTH_KEY,
    DEBUG,
    GOOGLE_MAPS_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const API_BASE_URL = DEBUG ? API_URL_DEV : API_URL;

// ----- Helpers ----- //

// Get Api Url
const getApiUrl = () => API_BASE_URL;

// Get hostname url
const getBaseUrl = () => {
    const apiUrl = API_BASE_URL;
    return apiUrl.split('/api')[0];
};

// defaults for axios
const api = axios.create({
    baseURL: API_BASE_URL,
});

// Get locational data
const locationData = name => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);

// Format params for api call
const formatParams = (payload, key) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }

    return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */
const getAuthKey = () => lockr.get(AUTH_KEY);

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

// ----- Api Functions ----- //

function fetchApi(opts, headers) {
    const data = (opts.method.toUpperCase() === 'GET') ? null : opts.body;
    const responseType = opts.responseType ? opts.responseType : null;
    const options = {
        method: opts.method,
        url: opts.url,
        params: opts.params,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        responseType,
        data,
    };

    if (!isEmpty(headers)) {
        options.headers = { ...options.headers, ...headers };
    }

    return api(options);
}

function fetchApiAuth(opts) {
    return new Promise((resolve, reject) => {
        if (isUndefined(getAuthKey())) {
            reject(new Error('not-authorised'));
        } else {
            const authDate = new Date();
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            resolve(fetchApi(opts, getAuthHeaders()));
        }
    });
}


function downloadFile(file) {
    return fetchApiAuth({
        url: `/file/download/${file.id}`,
        method: 'GET',
        responseType: 'blob',
    })
        .then(response => {
            const anchor = document.createElement('a');
            document.body.appendChild(anchor);
            const objectUrl = window.URL.createObjectURL(new Blob([response.data]));

            anchor.href = objectUrl;
            anchor.download = `${file.name}.pdf`;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        });
}

export {
    getApiUrl,
    getBaseUrl,
    locationData,
    formatParams,
    downloadFile,

    // -- Auth Key Helpers --//
    getAuthKey,
    getAuthHeaders,

    fetchApi,
    fetchApiAuth,
};
