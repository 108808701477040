import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as globalActions from '../../core/global/globalActions';
import * as userActions from '../../core/user/userActions';
import * as folderActions from '../../core/folder/folderActions';

import DOM from './folders';
import Loader from '../../components/layout/Loader';
import BrowserLoader from '../../components/BrowserView/BrowserLoader';

class Folders extends React.Component {
    constructor(props) {
        super(props);

        this.view = DOM;

        this.state = {
            minValue: 0,
            maxValue: 6,
        };
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.checkUserInfoRequest();
        actions.getAllFoldersRequest();
    }

    handleChange = value => {
        this.setState({
            minValue: (value - 1) * 6,
            maxValue: value * 6,
        });
    };

    render() {
        const { isFetching } = this.props;

        if (isFetching) {
            return <Loader><BrowserLoader /></Loader>;
        }

        return this.view(this.props);
    }
}

Folders.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    folderList: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        isFetching: state.folder.isFetching,
        folderList: state.folder.folderList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...userActions,
            ...folderActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
