import React from 'react';
import { FolderFilled } from '@ant-design/icons';

import './styles.scss';
import { Link } from 'react-router-dom';

export default function () {
    const { folderName = 'Folder name', amountFiles = 0, link = '/', description = '', image = '' } = this.props;

    return (
        <div className="folder">
            <Link to={`/folders${link}`}>
                <div className="folder-logo">
                    {image ? <img src={image} alt="" /> : <FolderFilled style={{ fontSize: '85px', color: '#000' }} />}
                </div>
            </Link>
            <div className="folder-info">
                <div className="main-info">
                    <Link to={`/folders${link}`}>
                        <span className="folder-name">{folderName}</span>
                    </Link>
                    <div className="folder-description">{description}</div>
                </div>
                <div className="files-info">{amountFiles} files</div>
            </div>
        </div>
    );
}
