import React from 'react';
import { Icon, Button } from 'antd';

import Layout from '../../components/layout/Layout';

import './styles.scss';

const Dashboard = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];

    return (
        <Layout
            className="login"
            title={<div>Welcome</div>}
            headerTitle={<div>Dashboard</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            floatingFooter
            breadcrumb={navigation}
            backLink={<Button><Icon type="left" /> BACK</Button>}>

            <div style={{ marginTop: '20px' }}>
                <h1>Dashboard</h1>
            </div>

        </Layout>
    );
};

export default Dashboard;
