import { Record } from 'immutable';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    CHECK_USER_INFO_REQUEST,
    CHECK_USER_INFO_SUCCESS,
    CHECK_USER_INFO_FAILURE,

    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,

    RESET_USER_PASSWORD_REQUEST,
    RESET_USER_PASSWORD_SUCCESS,
    RESET_USER_PASSWORD_FAILURE,
} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    loginUser: '',
    userSettings: {},
});

const initialState = new InitialState();

/**
 * ## userReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case LOGIN_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case CHECK_USER_INFO_REQUEST:
    case PASSWORD_RESET_REQUEST:
    case RESET_USER_PASSWORD_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
    case CHECK_USER_INFO_SUCCESS:
        return state.set('isFetching', false)
            .set('loginUser', (payload && payload.data && payload.data.user.email ? payload.data.user.email : 'Logged In User'))
            .set('userSettings', (payload && payload.data && payload.data.user ? payload.data.user : {}))
            .set('isLoggedIn', true);

    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
    case RESET_USER_PASSWORD_SUCCESS:
        return state.set('isFetching', false)
            .set('loginUser', '')
            .set('isLoggedIn', false);


    case PASSWORD_RESET_SUCCESS:
        return state.set('isFetching', false);

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
    case CHECK_USER_INFO_FAILURE:
    case PASSWORD_RESET_FAILURE:
    case RESET_USER_PASSWORD_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);

    default:
        return state;

    }
}
