import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import Routes from './core/routes';

import history from './core/utils/history';
import debug from './core/utils/debug';

import './styles/style.css';

/**
 * ## Actions
 *  The necessary actions for dispatching our bootstrap values
 */
import { setVersion } from './core/global/globalActions';

/**
 *  The version of the app but not displayed yet
 */
import pack from '../package.json';

/**
 * ### configureStore
 */
import configureStore from './core/store';

const globalStore = configureStore({});
const { store, persistor } = globalStore;

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));

// Get bugsnag error wrapper
const ErrorWrapper = debug.getWrapper();

ReactDOM.render(
    <ErrorWrapper>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history} basename="/">
                    <Routes persistor={persistor} />
                </Router>
            </PersistGate>
        </Provider>
    </ErrorWrapper>,
    document.getElementById('root'),
);
