import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';

import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    loginSuccess,
    loginFailure,

    logoutSuccess,

    checkUserInfoSuccess,
    checkUserInfoFailure,

    deleteTokenSuccess,

    passwordResetSuccess,
    passwordResetFailure,

    resetUserPasswordSuccess,
    resetUserPasswordFailure,
} from './userActions';

const {
    AUTH_KEY,
    API_LOGIN_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    CHECK_USER_INFO_REQUEST,
    RESET_USER_PASSWORD_REQUEST,
    PASSWORD_RESET_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.token);
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { email: action.payload.email, password: action.payload.password },
        });
        yield call(saveSessionToken, response);
        yield put(loginSuccess(response));
        history.push('/information');
    } catch (e) {
        if (e.message.indexOf('403') !== -1) {
            message.error('Access denied.');
        } else if (e.message.indexOf('402') !== -1) {
            message.error('Is not assigned to a company.');
        } else if (e.message.indexOf('405') !== -1) {
            message.error('User is not assigned to a company. Please contact a Meridian administrator.');
        } else {
            message.error('The given data was invalid.');
        }
        yield put(loginFailure(e.message ? e.message : e));
    }
}

function* deleteSessionToken() {
    lockr.rm(AUTH_KEY);
    lockr.rm(API_LOGIN_KEY);

    yield put(deleteTokenSuccess());
}

function* logoutRequest() {
    yield call(deleteSessionToken);

    localStorage.clear();

    yield put(logoutSuccess());

    history.push('/');
}

function* checkUserInfoRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/user',
        });
        yield put(checkUserInfoSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield call(deleteSessionToken);
        yield put(checkUserInfoFailure(e.message ? e.message : e));
    }
}


function* passwordReset(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/reset-password',
            body: action.payload,
        });

        yield put(passwordResetSuccess(response));
        message.success('Check your email, for password reset instructions');
    } catch (e) {
        message.error(e.response.data.error ? e.response.data.error : e.message);
        yield put(passwordResetFailure(e.response.data.error ? e.response.data.error : e.message));
    }
}

function* resetUserPassword(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'post',
            url: '/reset-user-password',
            body: action.payload,
        });
        yield put(resetUserPasswordSuccess(response));
        message.success('Password updated successfully.');
        history.push('/');
    } catch (e) {
        message.error(e.response.data.error ? e.response.data.error : e.message);
        yield put(resetUserPasswordFailure(e.response.data.error ? e.response.data.error : e.message));
    }
}


/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(CHECK_USER_INFO_REQUEST, checkUserInfoRequest),
        takeEvery(PASSWORD_RESET_REQUEST, passwordReset),
        takeEvery(RESET_USER_PASSWORD_REQUEST, resetUserPassword),
    ]);
}
