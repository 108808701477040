import React from 'react';
import { Col, Pagination } from 'antd';

import Layout from '../../components/layout/Layout';
import BrowserView from '../../components/BrowserView';

import './styles.scss';
import Folder from '../../components/Folder';

import { generateKey } from '../../core/utils/helpers';

export default function () {
    const { folderList } = this.props;

    return (
        <Layout
            className="folders"
            title={<div>Folders</div>}
            headerTitle={<div>Folders</div>}
            floatingHeader={false}
            showFooter={false}>
            <BrowserView headerText="Folders">
                {folderList && folderList.length > 0 && folderList.slice(this.state.minValue, this.state.maxValue).map(item => (
                    <Col key={generateKey(item.id)} md={12} xs={24}>
                        <Folder folderName={item.name}
                            tooltipText={item.description}
                            amountFiles={item.fileAmount}
                            image={item.image}
                            description={item.description}
                            link={`/${item.id}`} />
                    </Col>
                ))}
                {folderList.length === 0 ? (
                    <div className="no-files-wrapper">
                        No folders available for viewing
                    </div>
                ) : null}
            </BrowserView>
            {
                folderList.length > 6
                    ? (
                        <div id="pagination" className="pagination">
                            <Pagination
                                size="small"
                                defaultCurrent={1}
                                defaultPageSize={this.state.maxValue}
                                onChange={this.handleChange}
                                total={folderList.length} />
                        </div>
                    ) : null
            }

        </Layout>
    );
}
