import React from 'react';

import { Form, Input, Button, Row, Col } from 'antd';

import './styles.css';
import ForgetForm from './ForgetForm';

const FormItem = Form.Item;


export default function () {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
        <div className="login-wrap">
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <Form onSubmit={this.handleSubmit} layout="vertical" className="login-label">
                        <Row>
                            <Col xs={32}>
                                <FormItem label="Email">
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ],
                                    })(<Input />)}
                                </FormItem>

                                <FormItem label="Password">
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ],
                                    })(<Input type="password" />)}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={32}>
                                <Button htmlType="submit" className="signin_btn">
                                    Sign in
                                </Button>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '20px' }}>
                            <Col xs={32}>
                                <a href="/#" className="forgotten_btn" onClick={(e) => { e.preventDefault(); this.setModalForgetVisible(true); }}>
                                    Forgotten password
                                </a>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <ForgetForm isVisible={this.state.modalForgetVisible} onOk={() => this.setModalForgetVisible(false)} onCancel={() => this.setModalForgetVisible(false)} />
        </div>
    );
}
