import React from 'react';
import { mapObject } from 'underscore';
import { message, Icon } from 'antd';

const alert = {};

const success = (content, duration) => {
    message.success((
        <div className="alert alert-success">
            <Icon type="circle-tick" />
            {content}
        </div>
    ), duration || 4);
};

const error = (e, duration) => {
    let content = e;
    if (e.message) {
        content = e.message;
    }
    if (e.errors) {
        const messages = [];
        mapObject(e.errors, value => messages.push(value));
        content = messages.join(', ');
    }

    message.error((
        <div className="alert alert-error">
            <Icon type="circle-info" />
            {content}
        </div>
    ), duration || 4);
};

const info = (content, duration) => {
    message.info((
        <div className="alert alert-info">
            <Icon type="circle-info" />
            {content}
        </div>
    ), duration || 4);
};

alert.success = success;
alert.error = error;
alert.info = info;

export default alert;
