import { Component } from 'react';

import DOM from './browserContainer';

class BrowserContainer extends Component {

    constructor(props) {
        super(props);

        this.view = DOM;
    }

    render() {
        return this.view();
    }
}

export default BrowserContainer;
