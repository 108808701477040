import { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../core/utils/history';

import DOM from './information';

import * as userActions from '../../core/user/userActions';


class Information extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.checkUserInfoRequest();
    }

    handleAllFiles = () => {
        history.push('/folders');
    };

    render() {
        return this.view(this.props);
    }
}

Information.propTypes = {
    actions: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        userSettings: state.user.userSettings,
        image: state.user.userSettings.company ? state.user.userSettings.company.image : null,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Information);
