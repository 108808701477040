import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_ALL_FOLDERS_REQUEST: null,
    GET_ALL_FOLDERS_SUCCESS: null,
    GET_ALL_FOLDERS_FAILURE: null,

    GET_ALL_FILES_IN_FOLDER_REQUEST: null,
    GET_ALL_FILES_IN_FOLDER_SUCCESS: null,
    GET_ALL_FILES_IN_FOLDER_FAILURE: null,

    DELETE_FILE_REQUEST: null,
    DELETE_FILE_SUCCESS: null,
    DELETE_FILE_FAILURE: null,

    UPLOAD_FILE_REQUEST: null,
    UPLOAD_FILE_SUCCESS: null,
    UPLOAD_FILE_FAILURE: null,

    DOWNLOAD_FILE_REQUEST: null,
    DOWNLOAD_FILE_SUCCESS: null,
    DOWNLOAD_FILE_FAILURE: null,

    SEARCH_FILE_REQUEST: null,
    SEARCH_FILE_SUCCESS: null,
    SEARCH_FILE_FAILURE: null,

    SEARCH_FILE_TAG_REQUEST: null,
    SEARCH_FILE_TAG_SUCCESS: null,
    SEARCH_FILE_TAG_FAILURE: null,
});

export const {
    getAllFoldersRequest,
    getAllFoldersSuccess,
    getAllFoldersFailure,
} = createActions(
    constants.GET_ALL_FOLDERS_REQUEST,
    constants.GET_ALL_FOLDERS_SUCCESS,
    constants.GET_ALL_FOLDERS_FAILURE,
);

export const {
    getAllFilesInFolderRequest,
    getAllFilesInFolderSuccess,
    getAllFilesInFolderFailure,
} = createActions(
    constants.GET_ALL_FILES_IN_FOLDER_REQUEST,
    constants.GET_ALL_FILES_IN_FOLDER_SUCCESS,
    constants.GET_ALL_FILES_IN_FOLDER_FAILURE,
);

export const {
    deleteFileRequest,
    deleteFileSuccess,
    deleteFileFailure,
} = createActions(
    constants.DELETE_FILE_REQUEST,
    constants.DELETE_FILE_SUCCESS,
    constants.DELETE_FILE_FAILURE,
);

export const {
    uploadFileRequest,
    uploadFileSuccess,
    uploadFileFailure,
} = createActions(
    constants.UPLOAD_FILE_REQUEST,
    constants.UPLOAD_FILE_SUCCESS,
    constants.UPLOAD_FILE_FAILURE,
);

export const {
    downloadFileRequest,
    downloadFileSuccess,
    downloadFileFailure,
} = createActions(
    constants.DOWNLOAD_FILE_REQUEST,
    constants.DOWNLOAD_FILE_SUCCESS,
    constants.DOWNLOAD_FILE_FAILURE,
);

export const {
    searchFileRequest,
    searchFileSuccess,
    searchFileFailure,
} = createActions(
    constants.SEARCH_FILE_REQUEST,
    constants.SEARCH_FILE_SUCCESS,
    constants.SEARCH_FILE_FAILURE,
);

export const {
    searchFileTagRequest,
    searchFileTagSuccess,
    searchFileTagFailure,
} = createActions(
    constants.SEARCH_FILE_TAG_REQUEST,
    constants.SEARCH_FILE_TAG_SUCCESS,
    constants.SEARCH_FILE_TAG_FAILURE,
);
