import { Record } from 'immutable';

const {
    GET_ALL_FOLDERS_REQUEST,
    GET_ALL_FOLDERS_SUCCESS,
    GET_ALL_FOLDERS_FAILURE,

    GET_ALL_FILES_IN_FOLDER_REQUEST,
    GET_ALL_FILES_IN_FOLDER_SUCCESS,
    GET_ALL_FILES_IN_FOLDER_FAILURE,

    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAILURE,

    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,

    DOWNLOAD_FILE_REQUEST,
    DOWNLOAD_FILE_SUCCESS,
    DOWNLOAD_FILE_FAILURE,

    SEARCH_FILE_REQUEST,
    SEARCH_FILE_SUCCESS,
    SEARCH_FILE_FAILURE,

    SEARCH_FILE_TAG_REQUEST,
    SEARCH_FILE_TAG_SUCCESS,
    SEARCH_FILE_TAG_FAILURE,
} = require('./folderActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    folderList: [],
    filesList: [],
    subFolder: [],
    searchFile: [],
    currentFolder: {},
});

const initialState = new InitialState();

/**
 * ## folderReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function folderReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_ALL_FOLDERS_REQUEST:
    case GET_ALL_FILES_IN_FOLDER_REQUEST:
    case DELETE_FILE_REQUEST:
    case UPLOAD_FILE_REQUEST:
    case DOWNLOAD_FILE_REQUEST:
    case SEARCH_FILE_REQUEST:
    case SEARCH_FILE_TAG_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_ALL_FOLDERS_SUCCESS:
        return state.set('isFetching', false)
            .set('folderList', (payload && payload.data && payload.data.folders ? payload.data.folders : []));
    case GET_ALL_FILES_IN_FOLDER_SUCCESS:
        return state.set('isFetching', false)
            .set('filesList', (payload && payload.data && payload.data.files ? payload.data.files : []))
            .set('currentFolder', (payload && payload.data && payload.data.folder ? payload.data.folder : {}))
            .set('subFolder', (payload && payload.data && payload.data.sub_folders ? payload.data.sub_folders : []));
    case DELETE_FILE_SUCCESS:
        return state.set('isFetching', false)
            .set('filesList', (payload && payload.data && payload.data.files ? payload.data.files : []));
    case UPLOAD_FILE_SUCCESS:
    case DOWNLOAD_FILE_SUCCESS:
        return state.set('isFetching', true);
    case GET_ALL_FOLDERS_FAILURE:
    case GET_ALL_FILES_IN_FOLDER_FAILURE:
    case DELETE_FILE_FAILURE:
    case UPLOAD_FILE_FAILURE:
    case SEARCH_FILE_FAILURE:
    case SEARCH_FILE_TAG_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case DOWNLOAD_FILE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case SEARCH_FILE_SUCCESS:
    case SEARCH_FILE_TAG_SUCCESS:
        return state.set('isFetching', false)
            .set('searchFile', payload && payload.data && payload.data.files ? payload.data.files : []);
    default:
        return state;

    }
}
