import React from 'react';

import { Form, Input, Button, Modal } from 'antd';

import './styles.css';

const FormItem = Form.Item;


export default function () {
    const { form, isVisible, onOk, onCancel } = this.props;
    const { getFieldDecorator } = form;

    return (
        <Modal centered width={540} footer={null} visible={isVisible} onOk={onOk} onCancel={onCancel}>
            <h4 className="h-3 text-center">Reset password</h4>
            <div className="form-wrap">
                <Form onSubmit={this.handleSubmit} layout="vertical">
                    <FormItem label="New password">
                        {getFieldDecorator('newPassword', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input your new password',
                                },
                            ],
                        })(<Input type="password" />)}
                    </FormItem>
                    <FormItem label="Confirm new password">
                        {getFieldDecorator('confirmNewPassword', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please repeat your new password',
                                },
                            ],
                        })(<Input type="password" />)}
                    </FormItem>

                    <Button type="primary" htmlType="submit" className="wide">
                        Confirm password
                    </Button>
                </Form>
            </div>
        </Modal>
    );
}
