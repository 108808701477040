/* eslint-disable */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import lockr from 'lockr';
import { isUndefined } from 'underscore';

import NotFound from '../../pages/NotFound';

import alert from './alerts';
import { connect } from 'react-redux';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRY_TIME
} = require('../constants').default;

const onlyAuthorisedAllowed = () => {
    const response = lockr.get(AUTH_KEY);
    if (!isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!isUndefined(authDate)) {
            const aDate = new Date(authDate);
            const aNow = new Date();
            const milliseconds = aNow - aDate;
            const difference = Math.floor(milliseconds / 1000 / 60);
            if (difference >= LOCALSTORAGE_EXPIRY_TIME) {
                lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
                lockr.rm(AUTH_KEY);
                alert.error('Session expired. Please login again.');
                localStorage.clear();
                return false;
            }
        } else {
            lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
            lockr.rm(AUTH_KEY);
            alert.error('Session expired. Please login again.');
            localStorage.clear();
            return false;
        }
        return true;
    }
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
    lockr.rm(AUTH_KEY);
    alert.error('Session expired. Please login again.');
    localStorage.clear();
    return false;
};

const withNotActiveRedirect = (Component) => {
    const wrapperComponent = (props) => {
        if (props.userStatus !== "Active") {
            return (<Redirect to="/deactivated" />)
        }
        else if(props.companyStatus !== "Active") {
            return (<Redirect to="/login" />)
        }

        return <Component {...props}/>
    }

    const mapStateToProps = (state, ownProps) => {
        return {
            ...ownProps,
            companyStatus: state.user.userSettings.companyStatus,
            userStatus: state.user.userSettings.status,
        };
    }

    return connect(mapStateToProps)(wrapperComponent);

}

const DefaultRoute = ({ exact, path, component }) => <Route exact={exact} path={path || '/'} component={component} />;

const AuthRoute = ({ exact, path, component: Component }) => <Route exact={exact} path={path || '/'} render={(props) => (onlyAuthorisedAllowed() ? <Component {...props}/>: <Redirect to="/" />)} />;

const LoginRoute = ({ exact, path, component: Component }) => <Route exact={exact} path={path || '/'} render={(props) => (!onlyAuthorisedAllowed() ? <Component {...props}/> : <Redirect to="/folders" />)} />;

const CoreRouter = ({ children }) => (
    <Switch>
        {children}
        <DefaultRoute component={NotFound} />
    </Switch>
);

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    DefaultRoute,
    AuthRoute,
    LoginRoute,
    withNotActiveRedirect,
};
