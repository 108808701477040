import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'antd';
import { isObject } from 'underscore';

import Header from '../Header';

import icon from '../../../assets/favicon.ico';

import './styles.css';

const { APP_NAME } = require('../../../core/constants').default;

const { Content } = Layout;

export default function () {
    const {
        children, title, toolbar, breadcrumb, className, menu, backLink,
        showHeader, beforeHeader, afterHeader, floatingHeader, headerTitle,
        menuPlacement, menuWidth,
    } = this.props;

    return (
        <div className={`page-wrap ${className}`}>

            <Helmet
                title={!isObject(title) ? title : title.props.children}
                titleTemplate={`%s - ${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>

            {showHeader ? (
                <Header
                    beforeheader={beforeHeader}
                    afterHeader={afterHeader}
                    floating={floatingHeader}
                    title={headerTitle || title}
                    toolbar={toolbar}
                    backLink={backLink}
                    menu={menu}
                    breadcrumb={breadcrumb}
                    menuPlacement={menuPlacement}
                    menuWidth={menuWidth} />
            ) : null}

            <Content className="main-content">
                {children}
            </Content>
        </div>
    );
}
