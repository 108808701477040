import React from 'react';
import { Spin } from 'antd';

import './styles.css';

export default function () {
    const { children } = this.props;

    return (
        <Spin wrapperClassName="loader-wrapper" size="large">
            {children}
        </Spin>
    );
}
