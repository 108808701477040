import { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DOM from './file';
import * as folderActions from '../../core/folder/folderActions';

const PDFicon = require('../../assets/images/pdf.svg');
const DOCXicon = require('../../assets/images/doc.svg');
const XLSXicon = require('../../assets/images/xls.svg');
const PPTXicon = require('../../assets/images/ppt.svg');

class File extends Component {

    constructor(props) {
        super(props);

        this.view = DOM;
    }

    handleDelete = () => {
        const { file_id, actions, files, fileName } = this.props;

        const newFiles = files.filter((item) => item.id !== file_id);

        actions.deleteFileRequest({
            fileName,
            file_id,
            files: newFiles,
        });
    };

    getIcon = () => {
        const { fileExt } = this.props;
        switch (fileExt) {
        case 'pdf':
            return PDFicon;
        case 'xlsx':
            return XLSXicon;
        case 'pptx':
            return PPTXicon;
        case 'docx':
            return DOCXicon;
        default:
            return null;
        }
    };

    handleDownload = () => {
        const { actions, file_id, fileName, fileExt } = this.props;
        actions.downloadFileRequest({ id: file_id, name: fileName, ext: fileExt });
    };

    handleSearchTag(tag) {
        const { actions } = this.props;

        actions.searchFileTagRequest({
            tag: tag.tag,
        });
    }

    render() {
        return this.view(this.props);
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        owner: state.user.userSettings.name,
        files: state.folder.filesList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...folderActions,
        }, dispatch),
    };
}

File.propTypes = {
    actions: PropTypes.object.isRequired,
    file_id: PropTypes.number.isRequired,
    files: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired,
    fileExt: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(File);
