import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import * as globalActions from '../../core/global/globalActions';
import * as userActions from '../../core/user/userActions';

import DOM from './deactivated';

class Deactivated extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.checkUserInfoRequest();
    }

    render() {
        const { userStatus, companyStatus } = this.props;
        if (companyStatus === 'Active' && userStatus === 'Active') {
            return <Redirect to="/folders" />;
        }

        return this.view();
    }
}

Deactivated.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
    userStatus: PropTypes.string.isRequired,
    companyStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        userStatus: state.user.userSettings.status,
        companyStatus: state.user.userSettings.companyStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Deactivated);
