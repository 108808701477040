import React from 'react';
import { Dropdown, Menu, Layout, Avatar, Button } from 'antd';
import { HomeFilled, HomeOutlined, FileOutlined, FileFilled, DownOutlined, SearchOutlined } from '@ant-design/icons';

import './styles.scss';

import Breadcrumb from '../Breadcrumb';


import './styles.css';

const { Content, Header } = Layout;

export default function () {
    const { floating, beforeHeader, afterHeader, userName, logoSrc, avatar, breadcrumb } = this.props;

    const active = window.location.pathname.indexOf('/information') === 0;

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <div onClick={this.handleLogout}>Sign out</div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}

            <Header className="header__inner">
                <div className="header__logo">
                    <img src={logoSrc} alt="" />
                    <div className="header__text">
                        <span>Meridian</span>
                        <span>Client</span>
                        <span>Portal</span>
                    </div>
                </div>

                <div className="topNavigation">
                    <Button className={active ? 'active' : null} onClick={this.changeTabInformation}>{active ? <HomeFilled /> : <HomeOutlined />}  Information</Button>
                    <Button className={!active ? 'active' : null} onClick={this.changeTabFiles}>{!active ? <FileFilled /> : <FileOutlined />}  Files</Button>
                </div>

                <Dropdown overlay={menu} trigger={['click']}>
                    <div className="mainMenuBtn" onClick={e => e.preventDefault()}>
                        <div className="userName">{userName}</div>
                        <Avatar src={avatar}>{avatar === null ? userName.charAt(0).toUpperCase() : null}</Avatar>
                        <DownOutlined style={{ paddingLeft: 5, fontSize: 14 }} />
                    </div>
                </Dropdown>
            </Header>

            <div className="quickSearch" id="quickSearch">
                <Content>
                    {breadcrumb ? <Breadcrumb navigation={breadcrumb} /> : null}
                </Content>
                <div className="search">
                    <button type="submit" className="searchButton" onClick={this.handleSearch}>
                        <SearchOutlined />
                    </button>
                    <input type="text" id="searchTerm" className="searchTerm" placeholder="Quick search for a file in all folders" onKeyDown={this.handleSearchEnter} />
                </div>
            </div>
            {afterHeader}
        </div>
    );
}
