// APP CONSTATNS
const {
    REACT_APP_ENV,
    REACT_APP_VERSION,
    REACT_APP_TITLE,

    REACT_APP_URL,
    REACT_APP_API_URL,

    REACT_APP_BUGSNAG_KEY,
} = process.env;

const app = {
    ENV: REACT_APP_ENV,
    VERSION: REACT_APP_VERSION || '0.0.0',
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,

    APP_NAME: REACT_APP_TITLE || 'MER App',

    APP_URL: REACT_APP_URL,
    API_URL: REACT_APP_API_URL,

    AUTH_KEY: 'access-token',
    LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
    LOCALSTORAGE_EXPIRY_TIME: '30', // minutes

    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY,
};

export default app;
