import { Component } from 'react';

import DOM from './folder';

class Folder extends Component {

    constructor(props) {
        super(props);

        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

export default Folder;
