import { Component } from 'react';

import DOM from './browserHeader';

class BrowserHeader extends Component {

    constructor(props) {
        super(props);

        this.view = DOM;
        this.state = {
            modalVisible: false,
        };
    }

    componentDidMount() {
    }

    handleCancel = () => {
        this.setState({ modalVisible: false });
    }

    render() {
        return this.view(this.props);
    }
}

export default BrowserHeader;
