import { all } from 'redux-saga/effects';

import userSaga from './user/userSaga';
import folderSaga from './folder/folderSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        folderSaga(),
    ]);
}
