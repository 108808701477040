import React from 'react';

import { Button, Icon, Tooltip } from 'antd';

import './styles.scss';

export default function () {
    const { headerText, showButtons, goBack, description } = this.props;

    if (showButtons) {

        return (
            <div className="browser-header ant-row-flex-space-between">
                <div className="ant-row-flex">
                    <Button className="back-btn" shape="circle" icon="arrow-left" onClick={goBack} />
                    <h2 className="header-files">{headerText}</h2>
                    <Tooltip placement="right"
                        title={description}>
                        <Icon className="header-icons" type="info-circle" style={{ color: 'grey' }} />
                    </Tooltip>
                </div>
            </div>
        );
    }

    return (
        <div className="browser-header">
            <h2>{headerText}</h2>
        </div>
    );
}
