import React from 'react';
import { TagFilled } from '@ant-design/icons';
import { generateKey } from '../../core/utils/helpers';
import './styles.scss';

export default function () {
    const { fileName = 'File name', fileSize = '0.0 Mb', uploadedAt = '11/12/2020', description = '', tag = [], ownerFile = 'Meridian', fileExt = '' } = this.props;

    return (
        <div className="file">

            <div className="file-info">
                <button className="file-image-wrap" type="button" onClick={this.handleDownload}>
                    <img className="file-image" src={this.getIcon()} alt="" />
                    <div className="file-title">{`${fileName}.${fileExt}`}</div>
                </button>
                <div className="aboutFile">
                    <span className="file-description">{description}</span>
                    <div className="info">
                        <span className="file-size">{`${fileSize} | `}</span>
                        <span className="file-time">{uploadedAt}</span>
                        <span className="file-size">{` ${ownerFile}`}</span>
                    </div>
                    {
                        tag.length > 0
                            ? (
                                <span className="tags">
                                    <TagFilled style={{ paddingRight: 10 }} />
                                    {tag.map((item) => <div key={generateKey(item.id)} className="tag" onClick={() => this.handleSearchTag(item)}>{item.tag},</div>)}
                                </span>
                            ) : null
                    }
                </div>
            </div>
        </div>
    );
}
