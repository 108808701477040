import React from 'react';

import { Col, Row, Pagination } from 'antd';
import Layout from '../../components/layout/Layout';

import File from '../../components/File';

import './styles.scss';
import { generateKey } from '../../core/utils/helpers';

export default function () {
    const { searchFile } = this.props;

    return (
        <Layout>
            <Row gutter={[48, 24]}>
                {searchFile && searchFile.length > 0 && searchFile.slice(this.state.minValue, this.state.maxValue).map(item => (
                    <Col key={generateKey(item.id)} span={12}>
                        <File fileName={item.name}
                            fileSize={item.size ? item.size : '0.0 Mb'}
                            ownerFile={item.owner ? `by ${item.owner}` : 'by Meridian'}
                            tooltipText={item.description}
                            description={item.description}
                            file_id={item.id}
                            fileExt={item.ext}
                            tag={item.tags}
                            uploadedAt={`Updated by ${item.uploaded_at}`} />
                    </Col>
                ))}
                {searchFile.length === 0 ? (
                    <div className="no-files-wrapper">
                        No files were found.
                    </div>
                ) : null}
            </Row>

            {
                searchFile.length > 6
                    ? (
                        <div id="pagination" className="pagination">
                            <Pagination
                                size="small"
                                current={this.state.currentPage}
                                defaultCurrent={1}
                                defaultPageSize={6}
                                onChange={this.handleChange}
                                total={searchFile.length} />
                        </div>
                    ) : null
            }

        </Layout>
    );
}
