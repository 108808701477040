import { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DOM from './header';
import * as userActions from '../../../core/user/userActions';
import * as userFolders from '../../../core/folder/folderActions';
import history from '../../../core/utils/history';

class Header extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { status } = this.props;

        if (window.location.pathname.indexOf('/information') === 0 || status === 'Inactive') {
            document.getElementById('quickSearch').style.display = 'none';
        }
    }

    handleLogout = () => {
        const { actions } = this.props;

        actions.logoutRequest();
    };

    handleBack = async () => {
        await history.goBack();
        document.getElementById('quickSearch').style.display = 'none';
    };

    changeTabFiles = () => {
        history.push('/folders');
    };

    changeTabInformation = () => {
        history.push('/information');
    };

    handleSearch = () => {
        const { actions } = this.props;
        const { value } = document.getElementById('searchTerm');

        if (value !== '') {
            actions.searchFileRequest({
                type: 'name',
                name: value,
            });
        } else if (window.location.pathname.indexOf('/search') === 0) {
            history.push('/folders');
        }
    };

    handleSearchEnter = (e) => {
        const { actions } = this.props;
        const { value } = document.getElementById('searchTerm');

        if (e.key === 'Enter' && value !== '') {
            actions.searchFileRequest({
                type: 'name',
                name: value,
            });
        } else if (window.location.pathname.indexOf('/search') === 0) {
            history.push('/folders');
        }
    };

    render() {
        return this.view(this.props);
    }
}

Header.defaultProps = {
    breadcrumb: null,
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 256,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
    logoSrc: '',
    userName: '',
    status: '',
};

Header.propTypes = {
    breadcrumb: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    actions: PropTypes.object.isRequired,
    logoSrc: PropTypes.string,
    userName: PropTypes.string,
    avatar: PropTypes.object.isRequired,
    status: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        userName: state.user.userSettings.name,
        avatar: state.user.userSettings.logo,
        status: state.user.userSettings.status,
        logoSrc: state.user.userSettings.company ? state.user.userSettings.company.logo : null,
        searchFile: state.folder.searchFile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
            ...userFolders,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
