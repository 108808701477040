import React from 'react';

import { Form, Input, Button, Modal } from 'antd';

import './styles.css';

const FormItem = Form.Item;


export default function () {
    const { form, isVisible, onOk, onCancel } = this.props;
    const { getFieldDecorator } = form;

    return (
        <Modal centered width={540} footer={null} visible={isVisible} onOk={onOk} onCancel={onCancel}>
            <h4 className="h-3 text-center">Forgot password</h4>
            <div className="form-wrap">
                <Form onSubmit={this.handleSubmit} layout="vertical">
                    <FormItem label="Email">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ],
                        })(<Input placeholder="mail@mail.com" />)}
                    </FormItem>

                    <Button type="primary" htmlType="submit" className="wide">
                        Reset my password
                    </Button>
                </Form>
            </div>
        </Modal>
    );
}
