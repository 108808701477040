import React from 'react';

import Layout from '../../components/layout/Layout';

import './styles.scss';

const Deactivated = (props) => (
    <Layout
        className="deactivated"
        title={<div>Deactivated</div>}
        headerTitle={<div>Deactivated</div>}
        floatingHeader={false}
        footerContent={(
            <div style={{
                lineHeight: '30px',
                fontSize: '16px',
                float: 'right',
            }}>&copy; copyright 2020
            </div>
        )}
        floatingFooter
        {...props}>

        <div className="deactivated__wrapper">
            <h2>Company Account Deactivated</h2>
            <span>Your Meridian Client Portal account was deactivated by an administrator. If you think that this is incorrect, then please <a href="http://www.meridian-systems.co.uk/contact.html">contact Meridian</a></span>
        </div>

    </Layout>
);

export default Deactivated;
