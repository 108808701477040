import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import * as userActions from '../../core/user/userActions';

import DOM from './resetPasswordForm';

class BaseResetPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, token } = this.props;

                actions.resetUserPasswordRequest({
                    token,
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmNewPassword,
                });
            }
        });
    };

    render() {
        return this.view();
    }
}

BaseResetPasswordForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
};

const ResetPasswordForm = Form.create()(BaseResetPasswordForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
