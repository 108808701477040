import React from 'react';
import { Row } from 'antd';

import './styles.css';

import BrowserContainer from './BrowserContainer';
import BrowserHeader from './BrowserHeader';

export default function () {
    const { children, headerText, headerTextFolder, showButtons = false, goBack, description = null } = this.props;

    return (
        <BrowserContainer>
            <BrowserHeader headerText={headerText} goBack={goBack} showButtons={showButtons} description={description} />
            <h2 className={headerTextFolder !== null ? 'header-folders' : ''}>{headerTextFolder}</h2>
            <div className={headerTextFolder !== null ? 'browser-wrapper' : ''}>
                <Row gutter={[24, 24]} type="flex">
                    {children}
                </Row>
            </div>
        </BrowserContainer>
    );
}
