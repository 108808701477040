import { Component } from 'react';

import DOM from './browserLoader';

class BrowserLoader extends Component {

    constructor(props) {
        super(props);

        this.view = DOM;
        this.state = {
            modalVisible: false,
        };
    }

    componentDidMount() {
    }

    render() {
        return this.view(this.props);
    }
}

export default BrowserLoader;
