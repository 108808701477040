import React from 'react';
import { Route } from 'react-router-dom';
import lockr from 'lockr';

import { CoreRouter, DefaultRoute, AuthRoute, withNotActiveRedirect, LoginRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Information from '../pages/Information';
import Folders from '../pages/Folders';
import FilesGrid from '../pages/FilesGrid';
import FilesSearch from '../pages/Files';
import Deactivated from '../pages/Deactivated';

const {
    AUTH_KEY,
    API_LOGIN_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('./constants').default;

const logout = (props) => {
    const { persistor } = props;

    lockr.rm(AUTH_KEY);
    lockr.rm(API_LOGIN_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);

    localStorage.clear();
    lockr.flush();
    persistor.flush();
    persistor.purge();

    window.location.href = '/';
};

const Routes = (coreProps) => (
    <CoreRouter>
        <DefaultRoute exact path="/" component={Login} />
        <DefaultRoute exact path="/login" component={Login} />

        <AuthRoute exact path="/dashboard" component={Dashboard} />

        <AuthRoute exact path="/deactivated" component={Deactivated} />

        <AuthRoute exact path="/information" component={withNotActiveRedirect(Information)} />

        <AuthRoute exact path="/folders" component={withNotActiveRedirect(Folders)} />

        <AuthRoute exact path="/folders/:name" component={withNotActiveRedirect(FilesGrid)} />

        <AuthRoute exact path="/folders/:name/:name" component={withNotActiveRedirect(FilesGrid)} />

        <AuthRoute exact path="/folders/:name/:name/:name" component={withNotActiveRedirect(FilesGrid)} />

        <AuthRoute exact path="/folders/:name/:name/:name/:name" component={withNotActiveRedirect(FilesGrid)} />

        <AuthRoute exact path="/search" component={withNotActiveRedirect(FilesSearch)} />

        <Route exact path="/logout" render={() => logout(coreProps)} />

        <LoginRoute exact path="/password/reset/:token" component={Login} />
    </CoreRouter>
);

export default Routes;
