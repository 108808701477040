import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import * as userActions from '../../../core/user/userActions';

import DOM from './forgetForm';

class BaseForgetForm extends Component {

    constructor(props) {
        super(props);
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions } = this.props;

                actions.passwordResetRequest({
                    email: values.email,
                });
            }
        });
    };

    render() {
        return this.view();
    }
}

BaseForgetForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const ForgetForm = Form.create()(BaseForgetForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetForm);
