import React from 'react';
import { Col, Row, Skeleton } from 'antd';

import './styles.css';

import BrowserContainer from '../BrowserContainer';
import Layout from '../../layout/Layout';

export default function () {

    const navigation = [{ label: 'Folders', link: '/folders' }];

    return (
        <Layout
            title={<div>Loading</div>}
            headerTitle={<div>Loading</div>}
            floatingHeader={false}
            showFooter={false}
            breadcrumb={navigation}>

            <BrowserContainer>
                <div className="browser-header">
                    <h2>Loading</h2>
                </div>
                <div className="browser-wrapper">
                    <Row gutter={[24, 24]} type="flex">
                        <Col md={12} xs={24}>
                            <Skeleton active avatar />
                        </Col>
                        <Col md={12} xs={24}>
                            <Skeleton active avatar />
                        </Col>
                        <Col md={12} xs={24}>
                            <Skeleton active avatar />
                        </Col>
                        <Col md={12} xs={24}>
                            <Skeleton active avatar />
                        </Col>
                    </Row>
                </div>
            </BrowserContainer>

        </Layout>
    );
}
