/* eslint-disable */
import { Component } from 'react';
import DOM from './loader';

class Loader extends Component {

    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

export default Loader;
