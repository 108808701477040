/* eslint-disable no-shadow */
import { call, put, takeEvery, all, delay } from 'redux-saga/effects';
import { message } from 'antd';

import { fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    getAllFoldersSuccess,
    getAllFoldersFailure,

    getAllFilesInFolderSuccess,
    getAllFilesInFolderFailure,

    deleteFileSuccess,
    deleteFileFailure,

    uploadFileSuccess,
    uploadFileFailure,

    downloadFileSuccess,
    downloadFileFailure,

    searchFileSuccess,
    searchFileFailure,

    searchFileTagSuccess,
    searchFileTagFailure,
} from './folderActions';

const {
    GET_ALL_FOLDERS_REQUEST,
    GET_ALL_FILES_IN_FOLDER_REQUEST,
    DELETE_FILE_REQUEST,
    UPLOAD_FILE_REQUEST,
    DOWNLOAD_FILE_REQUEST,
    SEARCH_FILE_REQUEST,
    SEARCH_FILE_TAG_REQUEST,
} = require('./folderActions').constants;

function* makeAllFoldersRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/folder/all',
        });
        yield put(getAllFoldersSuccess(response));
    } catch (e) {
        message.error(e.message ? e.message : e);
        yield put(getAllFoldersFailure(e.message ? e.message : e));
    }
}

function* makeAllFilesInFoldersRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/folder/${action.payload.id}`,
        });
        yield put(getAllFilesInFolderSuccess(response));
    } catch (e) {
        message.error(e.response.data.error ? e.response.data.error : e.message);
        yield put(getAllFilesInFolderFailure(e.message ? e.message : e));
        history.push('/folders');
    }
}


function* makeDeleteFileRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'DELETE',
            url: '/file/delete',
            body: {
                file_id: action.payload.file_id,
            },
        });
        response.data.files = action.payload.files;
        yield put(deleteFileSuccess(response));
        message.success(`File ${action.payload.fileName} successfully deleted!`);
    } catch (e) {
        message.error(e.response.data.error ? e.response.data.error : e.message);
        yield put(deleteFileFailure(e.message ? e.message : e));
    }
}

function* makeUploadFileRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/file/save',
            body: {
                name: action.payload.name,
                description: action.payload.description,
                media_id: action.payload.media_id,
                folder_slug: action.payload.folder_slug,
            },
        });
        message.success(`File ${action.payload.name} successfully added!`);
        yield put(uploadFileSuccess(response));
        window.parent.location = window.parent.location.href;
    } catch (e) {
        message.error(e.response.data.error ? e.response.data.error : e.message);
        yield put(uploadFileFailure(e.message ? e.message : e));
    }
}

function* makeDownloadFileRequest(action) {
    try {
        const response = yield call(fetchApiAuth({
            url: `/file/download/${action.payload.id}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then(response => {
                const anchor = document.createElement('a');
                document.body.appendChild(anchor);
                const objectUrl = window.URL.createObjectURL(new Blob([response.data]));

                anchor.href = objectUrl;
                anchor.download = `${action.payload.name}.${action.payload.ext}`;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            })
            .catch(e => message.error(e.response.data.error ? e.response.data.error : e.message)));
        yield put(downloadFileSuccess(response));
    } catch (e) {
        yield delay(1000);
        yield put(downloadFileFailure(e.message ? e.message : e));
    }
}
function* makeSearchFileRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/folder/search',
            body: { name: action.payload.name },
        });
        yield put(searchFileSuccess(response));
        history.push('/search');
    } catch (e) {
        message.error(e.response.data.error ? e.response.data.error : e.message);
        yield put(searchFileFailure(e.message ? e.message : e));
        history.push('/folders');
    }
}

function* makeSearchFileTagRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/folder/search',
            body: { tag: action.payload.tag },
        });
        yield put(searchFileTagSuccess(response));
        history.push('/search');
    } catch (e) {
        message.error(e.response.data.error ? e.response.data.error : e.message);
        yield put(searchFileTagFailure(e.message ? e.message : e));
        history.push('/folders');
    }
}

/**
 * Watch actions
 */
export default function* folderSaga() {
    yield all([
        takeEvery(GET_ALL_FOLDERS_REQUEST, makeAllFoldersRequest),
        takeEvery(GET_ALL_FILES_IN_FOLDER_REQUEST, makeAllFilesInFoldersRequest),
        takeEvery(UPLOAD_FILE_REQUEST, makeUploadFileRequest),
        takeEvery(DELETE_FILE_REQUEST, makeDeleteFileRequest),
        takeEvery(DOWNLOAD_FILE_REQUEST, makeDownloadFileRequest),
        takeEvery(SEARCH_FILE_REQUEST, makeSearchFileRequest),
        takeEvery(SEARCH_FILE_TAG_REQUEST, makeSearchFileTagRequest),
    ]);
}
