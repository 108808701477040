import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';
import lockr from 'lockr';

import * as globalActions from '../../core/global/globalActions';

import DOM from './login';
import Loader from '../../components/layout/Loader';

const {
    AUTH_KEY,
} = require('../../core/constants').default;

class Login extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            changePasswordModal: false,
        };
    }

    // eslint-disable-next-line
    componentWillMount() {
        const response = lockr.get(AUTH_KEY);
        const { match, loginUser, companyStatus } = this.props;
        if (!isUndefined(match.params.token)) {
            this.setState({ changePasswordModal: true });
        }
        if (loginUser !== '' && companyStatus !== 'Inactive' && !isUndefined(response)) {
            window.location.href = '/information';
        }
    }

    componentDidMount() {
    }

    render() {
        const { isFetching } = this.props;

        if (isFetching) {
            return <Loader>{this.view()}</Loader>;
        }

        return this.view();
    }
}

Login.propTypes = {
    actions: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    loginUser: PropTypes.string.isRequired,
    companyStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
        loginUser: state.user.loginUser,
        companyStatus: state.user.userSettings.companyStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
