import React from 'react';

import './styles.css';

export default function () {
    const { children } = this.props;

    return (
        <div className="browser-container">
            {children}
        </div>
    );
}
