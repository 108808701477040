import { combineReducers } from 'redux';

import global from './global/globalReducer';
import user from './user/userReducer';
import folder from './folder/folderReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    folder,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        // eslint-disable-next-line
    }

    return appReducers(state, action);
};


export default rootReducer;
