import React from 'react';

import { Col, Pagination } from 'antd';
import Layout from '../../components/layout/Layout';
import BrowserView from '../../components/BrowserView';

import File from '../../components/File';
import Folder from '../../components/Folder';

import './styles.scss';
import { generateKey } from '../../core/utils/helpers';

export default function () {
    const { history, currentFolder, filesList, subFolder } = this.props;

    return (
        <Layout
            title={<div>{currentFolder.name}</div>}
            headerTitle={<div>{currentFolder.name}</div>}
            floatingHeader={false}
            showFooter={false}
            breadcrumb={this.navigations(currentFolder || null)}
            parents={currentFolder.parents}
            {...this.props}>

            <BrowserView headerText={currentFolder.name}
                headerTextFolder={subFolder.length > 0 ? 'Folders' : null}
                goBack={history.goBack}
                description={currentFolder.description}
                showButtons>
                {subFolder && subFolder.length > 0 && subFolder.slice(this.state.minValueFolders, this.state.maxValueFolders).map(item => (
                    <Col key={generateKey(item.id)} md={12} xs={24}>
                        <Folder folderName={item.name}
                            tooltipText={item.description}
                            amountFiles={item.fileAmount}
                            image={item.image}
                            description={item.description}
                            link={`/${item.parents.map((parent) => `${parent.id}/`).join('')}${item.id}`} />
                    </Col>
                ))}
            </BrowserView>

            {
                subFolder.length > 6
                    ? (
                        <div id="pagination" className="pagination">
                            <Pagination
                                size="small"
                                defaultCurrent={1}
                                defaultPageSize={this.state.maxValueFolders}
                                onChange={this.handleChangeFolders}
                                total={subFolder.length} />
                        </div>
                    ) : null
            }

            <BrowserView headerText="Files">
                {filesList && filesList.length > 0 && filesList.slice(this.state.minValueFiles, this.state.maxValueFiles).map(file => (
                    <Col key={generateKey(file.id)} md={12} xs={24}>
                        <File fileName={file.name}
                            fileSize={file.size ? file.size : '0.0 Mb'}
                            ownerFile={file.owner ? `by ${file.owner}` : 'by Meridian'}
                            description={file.description}
                            file_id={file.id}
                            fileExt={file.ext}
                            tag={file.tags}
                            uploadedAt={`Updated by ${file.updated_at}`} />
                    </Col>
                ))}
                {filesList.length === 0 ? (
                    <div className="no-files-wrapper">
                        No files available for viewing
                    </div>
                ) : null}
            </BrowserView>

            {
                filesList.length > 6
                    ? (
                        <div id="pagination" className="pagination">
                            <Pagination
                                size="small"
                                current={this.state.currentPageFiles}
                                defaultCurrent={1}
                                defaultPageSize={6}
                                onChange={this.handleChangeFiles}
                                total={filesList.length} />
                        </div>
                    ) : null
            }

        </Layout>
    );
}
