import React from 'react';
import { map } from 'underscore';
import { Link } from 'react-router-dom';
import { Breadcrumb, Icon } from 'antd';

import './styles.scss';

export default function () {
    const { navigation } = this.props;
    return (navigation
        ? (
            <Breadcrumb separator="<" className="breadcrumb">
                {map(navigation, (nav, navIndex) => (
                    <Breadcrumb.Item key={nav.key || navIndex} overlay={nav.overlay || null}>
                        {nav.icon ? <Icon type={nav.icon} /> : null}
                        {nav.link
                            ? <Link className={nav.className} to={nav.link}>{nav.label}</Link>
                            : <span className={nav.className}>{nav.label}</span>}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        )
        : null);
}
