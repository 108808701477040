import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as globalActions from '../../core/global/globalActions';
import * as userActions from '../../core/user/userActions';
import * as folderActions from '../../core/folder/folderActions';

import DOM from './files';
import Loader from '../../components/layout/Loader';

class FilesGrid extends React.Component {
    constructor(props) {
        super(props);

        this.view = DOM;

        this.state = {
            minValueFolders: 0,
            maxValueFolders: 6,
            minValueFiles: 0,
            maxValueFiles: 6,
            currentPageFiles: 1,
            navigation: [],
        };
    }

    componentDidMount() {
        const { actions, match } = this.props;

        const { params } = match;

        actions.checkUserInfoRequest();
        actions.getAllFilesInFolderRequest({
            id: params.name,
        });
    }

    navigations = (currentFolder) => {
        const nav = [];
        nav.push({ label: 'Folders', link: '/folders' });
        let _link = '/folders';
        // eslint-disable-next-line no-unused-expressions
        currentFolder && currentFolder.parents && currentFolder.parents.map(item => {
            _link += `/${item.id}`;
            return nav.push({ label: item.name, link: _link });
        });

        nav.push({
            label: `${currentFolder.name}`,
            link: `${_link}/${currentFolder.id}`,
        });
        return nav;
    };

    handleChangeFolders = value => {
        this.setState({
            minValueFolders: (value - 1) * 6,
            maxValueFolders: value * 6,
        });
    };

    handleChangeFiles = value => {
        this.setState({
            minValueFiles: (value - 1) * 6,
            maxValueFiles: value * 6,
            currentPageFiles: value,
        });
    };

    render() {
        const { isFetching } = this.props;

        if (isFetching) {
            return <Loader>{this.view(this.props)}</Loader>;
        }

        return this.view(this.props);
    }
}

FilesGrid.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    filesList: PropTypes.array.isRequired,
    currentFolder: PropTypes.object.isRequired,
    subFolder: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        isFetching: state.folder.isFetching,
        filesList: state.folder.filesList,
        subFolder: state.folder.subFolder,
        currentFolder: state.folder.currentFolder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...userActions,
            ...folderActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesGrid);
