import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    CHECK_USER_INFO_REQUEST: null,
    CHECK_USER_INFO_SUCCESS: null,
    CHECK_USER_INFO_FAILURE: null,

    PASSWORD_RESET_REQUEST: null,
    PASSWORD_RESET_SUCCESS: null,
    PASSWORD_RESET_FAILURE: null,

    RESET_USER_PASSWORD_REQUEST: null,
    RESET_USER_PASSWORD_SUCCESS: null,
    RESET_USER_PASSWORD_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);

export const {
    checkUserInfoRequest,
    checkUserInfoSuccess,
    checkUserInfoFailure,
} = createActions(
    constants.CHECK_USER_INFO_REQUEST,
    constants.CHECK_USER_INFO_SUCCESS,
    constants.CHECK_USER_INFO_FAILURE,
);

export const {
    passwordResetRequest,
    passwordResetSuccess,
    passwordResetFailure,
} = createActions(
    constants.PASSWORD_RESET_REQUEST,
    constants.PASSWORD_RESET_SUCCESS,
    constants.PASSWORD_RESET_FAILURE,
);

export const {
    resetUserPasswordRequest,
    resetUserPasswordSuccess,
    resetUserPasswordFailure,
} = createActions(
    constants.RESET_USER_PASSWORD_REQUEST,
    constants.RESET_USER_PASSWORD_SUCCESS,
    constants.RESET_USER_PASSWORD_FAILURE,
);
